import { Theme, ThemeContext } from '@emotion/react';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Color from 'color';
import throttle from 'lodash.throttle';
import React from 'react';
import { Box, Flex } from './grid';
const SCROLLER_BUTTON_SIZE = 45;

interface Props {
  activeItem: string;
  items: any[];
  setActiveItem: any;
  className?: string;
  rtl?: boolean;
  bg?: string;
}

const Tabs = ({
  activeItem,
  items,
  setActiveItem,
  className,
  rtl,
  bg,
}: Props) => {
  const scrollerElRef = React.useRef<HTMLDivElement>(null);
  const [canScrollLeft, setCanScrollLeft] = React.useState(false);
  const [canScrollRight, setCanScrollRight] = React.useState(false);
  const [height, setHeight] = React.useState(0);

  const scroll = (v) => {
    scrollerElRef.current?.scrollBy({ left: v, top: 0, behavior: 'smooth' });
  };

  const ensureActiveItemInView = React.useCallback(() => {
    const containerRect =
      scrollerElRef.current.getBoundingClientRect() as DOMRect;
    const tabEl = scrollerElRef.current.querySelector(`#${activeItem}`);
    const tabRect = tabEl.getBoundingClientRect() as DOMRect;
    const startC = containerRect.left + SCROLLER_BUTTON_SIZE;
    const endC =
      containerRect.left + containerRect.width - SCROLLER_BUTTON_SIZE;
    const startT = tabRect.left;
    const endT = tabRect.left + tabRect.width;
    const isInView = startT >= startC && endT <= endC;

    if (!isInView) {
      scrollerElRef.current?.scrollBy(endT - endC, 0);
    }
  }, [activeItem]);

  const updateScrollState = () => {
    // TODO: Trigger on resize
    const scrollSpace =
      scrollerElRef.current.scrollWidth - scrollerElRef.current.clientWidth + 1;

    setCanScrollLeft(scrollerElRef.current.scrollLeft !== 0);
    setCanScrollRight(scrollerElRef.current.scrollLeft + 1 < scrollSpace);
    setHeight(scrollerElRef.current.clientHeight - 1);
  };

  const onScroll = throttle(() => {
    updateScrollState();
  }, 100);

  React.useEffect(() => {
    ensureActiveItemInView();
    updateScrollState();
  }, [activeItem, ensureActiveItemInView]);

  const theme = React.useContext<Theme>(ThemeContext as any);

  return (
    <Box
      className={className}
      style={{
        position: 'relative',
        height: 45,
      }}
    >
      <Box
        ref={scrollerElRef}
        onScroll={onScroll}
        sx={{
          overflowX: 'auto',
          overflowY: 'hidden',
          position: 'relative',
          top: 0,
        }}
      >
        <Flex
          flexWrap="nowrap"
          alignSelf="flex-start"
          flex="0 1"
          px={3}
          sx={{
            ...(rtl
              ? {
                  '& > div:first-of-type': {
                    marginLeft: 'auto',
                  },
                }
              : {}),
            minWidth: 0,
          }}
        >
          {items.map((item, idx) => (
            <Flex
              flexShrink={0}
              as="button"
              type="button"
              key={idx}
              tabIndex={0}
              id={`${item.value}`}
              onClick={() => setActiveItem(item.value)}
              className={`${activeItem === item.value ? 'active' : ''}`}
              px={3}
              minHeight="45px"
              maxHeight="45px"
              fontFamily="heading"
              fontWeight={500}
              fontSize="inherit"
              alignItems="center"
              sx={{
                whiteSpace: 'nowrap',
                cursor: 'pointer',
                // backgroundColor: 'rgba(255, 255, 255, 0.6)',
                borderBottomWidth: '3px',
                borderBottomStyle: 'solid',
                borderBottomColor: 'transparent',

                '&:hover': {
                  color: 'brand3.7',
                  borderBottomColor: 'brand3.7',
                },

                '&:not(:last-child)': {
                  marginRight: '2px',
                },

                '&.active, &:active': {
                  color: 'brand3.7',
                  borderBottomColor: 'brand3.7',
                },
              }}
            >
              {item.label}
            </Flex>
          ))}
        </Flex>
      </Box>

      <Box
        as="button"
        onClick={() => scroll(-200)}
        style={{
          display: canScrollLeft ? undefined : 'none',
        }}
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          height: height,
          background: `linear-gradient(to right, ${
            theme.colors['background']
          } 45%, ${Color(theme.colors['background']).alpha(0).string()})`,
          textAlign: 'left',
          cursor: 'pointer',
          borderRadius: 0,
          color: 'text',
          width: SCROLLER_BUTTON_SIZE,
          paddingLeft: 3,
        }}
      >
        <FontAwesomeIcon icon={faChevronLeft} size={'sm'} />
      </Box>

      <Box
        as="button"
        onClick={() => scroll(200)}
        style={{
          display: canScrollRight ? undefined : 'none',
        }}
        sx={{
          position: 'absolute',
          right: -1,
          top: 0,
          height: height,
          background: `linear-gradient(to left, ${
            theme.colors['background']
          } 45%, ${Color(theme.colors['background']).alpha(0).string()})`,
          textAlign: 'right',
          borderRadius: 0,
          color: 'text',
          width: SCROLLER_BUTTON_SIZE,
          paddingRight: 3,
        }}
      >
        <FontAwesomeIcon icon={faChevronRight} size={'sm'} />
      </Box>
    </Box>
  );
};

export default Tabs;
