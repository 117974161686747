import React from 'react';
import { Box, BoxProps } from 'shared/grid';
import { trimTextNode } from '../typography';

interface LabelProps {
  invalid?: boolean;
  isFocused?: boolean;
}

export const Label = React.forwardRef<HTMLDivElement, BoxProps & LabelProps>(
  function Label({ invalid, isFocused, ...props }, ref) {
    return (
      <Box
        tx="text"
        variant="label"
        as="label"
        ref={ref as any}
        __css={{
          color: invalid ? 'alert' : undefined,
          display: 'block',
          fontSize: 2,
          // ...trimTextNode({ lineHeight: Number(props.lineHeight) || 1.6 }),
        }}
        {...props}
      />
    );
  }
);

export default Label;
