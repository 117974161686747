export const invalidInput = {
  '&:not(:focus)': {
    borderColor: 'alert',
    backgroundColor: 'invalidInputBackground',

    '&::placeholder': {
      color: 'alert',
    },
  },
};
