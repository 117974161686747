import { Box, BoxProps } from '../grid';

export const FormError = (props: BoxProps) => (
  <Box
    fontFamily="body"
    fontSize={1}
    fontStyle="italic"
    fontWeight="heading"
    width="100%"
    color="alert"
    __css={{
      mt: 1,
    }}
    {...props}
  />
);

export default FormError;
