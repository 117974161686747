import ReactDOM from 'react-dom';

const Portal = ({ children, target = null }) => {
  if (typeof window !== 'object') {
    return null;
    // return <Box>{children}</Box>;
  }

  if (window['noPortal']) {
    return children;
  }

  const renderTarget =
    target || window['shadowRoot'] || document.body || window['shadowRoot'];
  //   console.log(renderTarget, children);

  return ReactDOM.createPortal(children, renderTarget);
  // return ReactDOM.createPortal(
  //   <Box color="text">{children}</Box>,
  //   renderTarget
  // );
};

export default Portal;
